import services from '@/services';
import { Button, LoadingSpinner, TextInput } from '@components/tailwind';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import { useQuery } from 'react-query';

const MAX_SHOWN_RESULTS = 5;

type SearchResult = {
  displayName: string;
  url: string;
  id: number;
  categoryName: string;
  imageThumbs: {
    contentType: string;
    url: string;
  }[];
  itemId: string;
  warranyPeriod: string;
};

const NavSearch = () => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const navSearchInputRef = useRef<HTMLInputElement>(null);
  const searchResultsCount = searchResults.length;

  const { data, isFetching } = useQuery({
    queryKey: ['search', searchTerm],
    queryFn: async () => {
      if (searchTerm) {
        services.zaraz.ecommerce.productsSearched(searchTerm);
      }

      return axios.get('/search', {
        params: {
          keywords: searchTerm,
        },
      });
    },
    onError: () => {
      setSearchResults([]);
    },
    keepPreviousData: true,
  });

  useEffect(() => {
    if (data) {
      setSearchResults(data.data);
    }
  }, [data]);

  useEffect(() => {
    const searchMenu = document?.querySelector('#SearchMenu')?.parentElement?.parentElement;

    if (!searchMenu) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation: any) => {
        console.log({ mutation, target: mutation.target });
        if (mutation.target.classList.contains('plum-menu--active-item')) {
          setTimeout(() => {
            navSearchInputRef.current?.focus();
          }, 400);
        }
      });
    });

    observer.observe(searchMenu, {
      childList: false,
      subtree: false,
      attributes: true,
      attributeFilter: ['class'],
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchTerm(searchInputValue);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchInputValue]);

  const onClose = () => {
    const activeItem = document.querySelector('.plum-menu--active-item');
    if (activeItem) {
      activeItem.classList.remove('plum-menu--active-item');
    } else {
      document.body.click();
    }
  };

  const onItemClick = (item: SearchResult, index: number) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    services.zaraz.ecommerce.productClicked('search_suggestions', 'Search Suggestions', {
      product_id: item.id.toString(),
      name: item.displayName,
      category: item.categoryName,
      position: index
    });

    window.location.href = item.url;
  };

  return (
    <div
      id='navSearch'
      className='tw-fixed tw-inset-x-0 tw-top-0 tw-z-[9999] tw-max-h-[100dvh] tw-overflow-y-auto tw-bg-white tw-shadow-lg'>
      <div className='tw-relative tw-mx-auto tw-h-full tw-w-full tw-max-w-screen-xl'>
        <AiOutlineClose
          role='button'
          className='tw-absolute tw-right-4 tw-top-4 tw-h-4 tw-w-4 tw-cursor-pointer md:tw-h-5 md:tw-w-5'
          onClick={onClose}
        />

        <div className='tw-flex tw-w-full tw-justify-center tw-px-5 tw-py-5 md:tw-px-8'>
          <div className='tw-mx-auto tw-mt-8 tw-w-full tw-max-w-[380px] xs:tw-mt-0'>
            <div className='tw-relative'>
              <TextInput
                ref={navSearchInputRef}
                value={searchInputValue}
                setValue={setSearchInputValue}
                type='search'
                name='navSearch'
                id='navSearchInput'
                wrapperClassName='navSearchContainer'
                className='tw-w-full tw-pr-10'
                placeholder='What are you looking for?'
              />
              <button className='tw-absolute tw-right-0 tw-top-0 tw-flex tw-h-full tw-w-10 tw-items-center tw-justify-center'>
                <AiOutlineSearch size={18} />
              </button>
            </div>

            <div className='tw-relative'>
              {isFetching && (
                <div className='tw-absolute tw-inset-0 tw-z-[2] tw-flex tw-items-center tw-justify-center tw-bg-white/75'>
                  <LoadingSpinner inline delay={0} />
                </div>
              )}

              <div className='tw-relative tw-z-[1] tw-mt-8'>
                {!searchTerm && searchResultsCount > 0 && (
                  <p className='tw-mb-4 tw-border-b tw-border-b-neutrals-light-grey tw-pb-4 tw-text-center tw-text-xl tw-font-bold tw-uppercase tw-text-body'>
                    TOP PRODUCTS
                  </p>
                )}

                {searchResultsCount > 0 ? (
                  <>
                    <div className='tw-flex tw-flex-col tw-gap-3'>
                      {searchResults.slice(0, MAX_SHOWN_RESULTS).map((result, index) => (
                        <SearchResultItem
                          key={result.id}
                          item={result}
                          onClick={onItemClick(result, index)}
                        />
                      ))}
                    </div>

                    {searchResultsCount > MAX_SHOWN_RESULTS && (
                      <div className='tw-mt-4'>
                        <Button variant='link-body' href={`/search?keywords=${searchTerm}`}>
                          Show all {searchResultsCount} results
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <p className='tw-text-center tw-text-xl tw-font-bold tw-text-muted'>
                    We couldn't find any results for “{searchTerm}”
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavSearch;

const SearchResultItem = ({
  item,
  onClick,
}: {
  item: SearchResult;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
  return (
    <a
      onClick={onClick}
      href={item.url}
      className='tw-flex tw-items-center tw-gap-4 tw-text-body tw-no-underline tw-transition-colors hover:tw-text-core-deep-navy hover:tw-no-underline'>
      <div className='tw-inline-flex tw-h-[100px] tw-w-[100px] tw-shrink-0 tw-grow-0 tw-justify-center'>
        <img src={item.imageThumbs[0].url} alt={item.displayName} className='tw-object-contain' />
      </div>
      <div className='tw-w-[calc(100%_-_116px)] tw-shrink-0 tw-grow-0 tw-justify-center'>
        <p className='tw-mb-0 tw-text-base tw-font-bold'>{item.displayName}</p>
      </div>
    </a>
  );
};
