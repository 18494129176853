import { Disclosure, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { supportLinks } from '../mobile-nav.data';
import classNames from 'classnames';
import services from '@/services';
import { Markdown as MD } from '@features/markdown/components/Markdown';

function SubNavCMS({ element, onBack }: { element: any; onBack: () => void }) {
  function SubNavHeader({ title, children }: { title: string; children: React.ReactNode }) {
    return (
      <div className='tw-w-full'>
        <button
          onClick={onBack}
          className='tw-flex tw-w-full tw-items-center tw-justify-start tw-bg-core-deep-navy tw-px-[25px] tw-py-[10px] tw-text-core-golden-yellow'>
          <i className='fa fa-chevron-left tw-text-white' />
          <div className='tw-ml-3 tw-text-sm tw-font-bold'>{title}</div>
        </button>
        <div className='tw-max-h-[89vh] tw-overflow-y-auto [&::-webkit-scrollbar]:tw-w-0'>{children}</div>
      </div>
    );
  }
  if (!element) return null;

  const isMulti = element.children.filter((e) => e.elementType === 'links').length > 1;
  return (
    <SubNavHeader title={element.attributes.label}>
      {element.children.map((c, i) => (
        <Element key={i} {...c} {...{ isMulti }} />
      ))}
    </SubNavHeader>
  );
}

const Element = ({ elementType, ...props }: any) => {
  switch (elementType) {
    case 'blurb':
      return <Blurb {...props} />;

    case 'links':
      return <Links {...props} />;

    default:
      const isDev = window.DD_ENV === 'dev';
      return isDev ? (
        <div className='tw-border-1 tw-m-2 tw-bg-pink-200 tw-p-2'>
          Not Implemented: <b>{elementType}</b>
        </div>
      ) : null;
  }
};

const Links = ({ attributes, children, isMulti }) => {
  return (
    <div className='tw-px-6'>
      {isMulti ? (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button as={Fragment}>
                <button
                  className={classNames(
                    'tw-block tw-w-full tw-py-5 hover:tw-text-brand-black hover:!tw-no-underline',
                    {
                      'tw-border-b': !open,
                    }
                  )}>
                  <div className='tw-flex tw-items-center tw-justify-between'>
                    <div>
                      <div className='tw-flex tw-items-center tw-space-x-1 tw-text-lg tw-font-normal'>
                        <div>{attributes.title}</div>
                      </div>
                    </div>
                    <div className='relative'>
                      <i
                        className={classNames('fa fa-chevron-down tw-transform tw-transition-transform', {
                          '-tw-scale-y-100': open,
                          'tw-scale-y-100': !open,
                        })}></i>
                    </div>
                  </div>
                </button>
              </Disclosure.Button>
              <Transition
                show={open}
                className='tw-overflow-hidden tw-border-b tw-pb-4'
                enter='tw-transition tw-transition-[max-height] tw-duration-300 tw-ease-in'
                enterFrom='tw-transform tw-max-h-0'
                enterTo='tw-transform tw-max-h-screen'
                leave='tw-transition tw-fransform tw-transition-[max-height] tw-duration-300 tw-ease-out'
                leaveFrom='tw-max-h-screen'
                leaveTo='tw-max-h-0'>
                <Disclosure.Panel static>
                  <div className='tw-ml-5 tw-flex tw-flex-col tw-space-y-3 tw-pb-2'>
                    {children.map((link, index) => (
                      <a
                        key={index}
                        href={link.attributes.url}
                        className='tw-block tw-w-full tw-py-3 tw-text-base tw-font-medium hover:tw-text-brand-black hover:!tw-no-underline'
                        onClick={() => {
                          services.zaraz.ecommerce.productClicked('main_nav', 'Main Nav', {name: link.attributes.label});
                        }}>
                        {link.attributes.label}
                      </a>
                    ))}
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      ) : (
        children.map((choice, index) => (
          <a
            key={index}
            href={choice.attributes.url}
            onClick={() => {
              services.zaraz.ecommerce.productClicked('main_nav', 'Main Nav', {name: choice.attributes.label});
            }}
            className={classNames(
              'tw-block tw-w-full tw-py-5 hover:tw-text-brand-black hover:!tw-no-underline',
              {
                'tw-border-b': index !== children.length - 1,
              }
            )}>
            <div className='tw-flex tw-items-center tw-justify-between'>
              <div>
                <div className='tw-flex tw-items-center tw-space-x-1 tw-text-lg tw-font-normal'>
                  <div>{choice.attributes.label}</div>
                </div>
              </div>
            </div>
          </a>
        ))
      )}
    </div>
  );
};

const Blurb = ({ attributes }) => {
  return (
    <div className='tw-p-6'>
      <a
        href={attributes.url}
        className='tw-block tw-w-full tw-text-brand-black hover:tw-text-brand-black hover:!tw-no-underline'
        onClick={() => {
          services.zaraz.ecommerce.productClicked('main_nav', 'Main Nav', {name: attributes.title})
        }}>
        <img
          src={attributes.image.mobile.url}
          alt={attributes.image.alt}
          className='tw-w-full tw-rounded-lg tw-object-cover'
        />
        <div className='tw-mt-2.5 tw-text-xs tw-font-bold'>
          <MD source={attributes.title} />
        </div>
        <p className='tw-text-xs'>
          <MD source={attributes.text} />
        </p>
      </a>
    </div>
  );
};

export function MobileNavLinksCMS({ elements: rawElements = '' }) {
  const elements = useMemo(() => {
    return JSON.parse(rawElements);
  }, [rawElements]);

  const [currentElement, setCurrentElement] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleWrapperClick = () => {
      setSubMenuOpen(false);
    };
    document.querySelector('.plum-mob-menu-wrapper').addEventListener('click', handleWrapperClick);
    return () =>
      document.querySelector('.plum-mob-menu-wrapper').removeEventListener('click', handleWrapperClick);
  }, [setCurrentElement]);

  return (
    <div
      className={classNames('tw-z-10 tw-bg-white', {
        'tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0': !!subMenuOpen,
      })}>
      <Transition
        show={subMenuOpen}
        className='tw-transform tw-transition-transform'
        enter='tw-duration-300 tw-absolute tw-inset-x-0'
        enterFrom='tw-translate-x-full'
        enterTo='tw-translate-x-0'
        entered='tw-static'
        leave='tw-absolute tw-inset-x-0'
        leaveFrom='tw-translate-x-0'
        unmount={false}
        leaveTo='tw-translate-x-full'>
        <SubNavCMS element={currentElement} onBack={() => setSubMenuOpen(false)} />
      </Transition>
      <Transition
        show={!subMenuOpen}
        className='tw-transform tw-transition-transform'
        enterFrom='-tw-translate-x-full'
        enterTo='tw-translate-x-0'
        leave='tw-duration-300'
        leaveFrom='tw-translate-x-0'
        leaveTo='-tw-translate-x-full'
        unmount={false}
        beforeLeave={() => {
          setScrollY(document.getElementById('MobileNavMenuRoot').scrollTop);
        }}
        beforeEnter={() => {
          document.getElementById('MobileNavMenuRoot').scrollTo({ top: scrollY });
        }}>
        <ul className='plum-mob-menu__menu-links'>
          {elements.map((link, index) => (
            <li key={index}>
              <a
                href={link.attributes.url}
                onClick={(e) => {
                  if (link.children?.length > 0) {
                    e.preventDefault();
                    setCurrentElement(link);
                    setSubMenuOpen(true);
                  } else {
                    services.zaraz.ecommerce.productClicked('main_nav', 'Main Nav', {name: link.attributes.label});
                  }
                }}
                className='tw-flex tw-flex-1 tw-items-center tw-justify-between'>
                <span>{link.attributes.label}</span>
                {link.children?.length > 0 && (
                  <img
                    src={require('/public/images/rtic/rebranded/icon-arrow-right.svg')}
                    className='tw-max-h-full tw-w-full tw-max-w-[20px]'
                  />
                )}
              </a>
            </li>
          ))}
        </ul>
        <p className='plum-mob-menu__group-title'>Support</p>
        <ul className='plum-mob-menu__menu-links plum-mob-menu__menu-links--w-icons'>
          {supportLinks.map((link, index) => (
            <li key={index}>
              <a
                href={link.onClick ? '#' : link.href}
                onClick={(e) => {
                  services.zaraz.custom.contentClicked('mobile_navigation', link.ariaLabel);
                  if (typeof link.onClick === 'function') {
                    e.preventDefault();
                    link.onClick();
                  }
                }}>
                <div className='plum-mob-menu__img-wrapper'>
                  <span aria-label={link.ariaLabel} className={`plum-icon ${link.iconClassName}`}></span>
                </div>
                <span>{link.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Transition>
    </div>
  );
}
