export type NavLink = {
  name?: string;
  href?: string;
  onClick?: () => void;
  iconClassName?: string;
  ariaLabel?: string;
};
export const supportLinks: NavLink[] = [
  {
    name: 'Order Status',
    href: jsRoutes.controllers.OrderStatusController.lookupOrderStatus().url,
    iconClassName: 'plum-icon-order-status plum-icon-black',
    ariaLabel: 'Order Status',
  },
  {
    name: 'Live Chat',
    onClick: () => window.CCMB.showChat(),
    iconClassName: 'plum-icon-livechat plum-icon-black',
    ariaLabel: 'Live Chat',
  },
  {
    name: 'help@rticoutdoors.com',
    href: 'mailto:help@rticoutdoors.com',
    iconClassName: 'plum-icon-email plum-icon-black',
    ariaLabel: 'Email',
  },
  {
    name: '1-855-527-6993 (8am - 5pm CT)',
    href: 'tel:1-855-527-6993',
    iconClassName: 'plum-icon-phone plum-icon-black',
    ariaLabel: 'Phone',
  },
  {
    name: 'Visit our help center',
    href: jsRoutes.controllers.Application.static('help').url,
    iconClassName: 'plum-icon-support',
    ariaLabel: 'Help Center',
  },
];
