import React, { useMemo } from 'react';
import ErrorBoundary from '@components/tailwind/ErrorBoundary';
import { createRoot } from 'react-dom/client';
import { HeaderDropdownMenu } from '@components/tailwind/HeaderDropdownMenu';
import Element from './features/cms/components/Element';

const DropdownRoot = (props) => {
  const elements = useMemo(() => {
    return JSON.parse(props.elements);
  }, [props.elements]);

  const handleDropdownOpen = () => {};

  return (
    <>
      {elements.map((e, i) =>
        e.children?.length > 0 ? (
          <HeaderDropdownMenu
            key={i}
            name={e.attributes.label}
            onDropdownOpen={handleDropdownOpen}
            className={props.navCenterItemsClasses}>
            <div className='tw-absolute tw-inset-x-0 tw-z-[999] tw-bg-white tw-font-body tw-text-black tw-shadow-md'>
              <div className='tw-mx-auto tw-flex tw-max-w-[820px] tw-gap-5 tw-py-5'>
                {e.children.map((c, i) => (
                  <Element key={i} {...c} />
                ))}
              </div>
            </div>
          </HeaderDropdownMenu>
        ) : (
          <div key={i}>
            <a className={props.navCenterItemsClasses} href={e.attributes.url}>
              {e.attributes.label}
            </a>
          </div>
        )
      )}
    </>
  );
};

const rootEl = document.getElementById('DropdownRoot');

if (rootEl) {
  createRoot(rootEl).render(
    <ErrorBoundary silent>{React.createElement(DropdownRoot, rootEl.dataset)}</ErrorBoundary>
  );
}
