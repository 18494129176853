import { MobileNavLinksCMS } from './features/mobile-nav/components/MobileNavLinks';
import ErrorBoundary from '@components/tailwind/ErrorBoundary';
import { createRoot } from 'react-dom/client';
import React from 'react';

const rootEl = document.getElementById('MobileNavMenuRoot');

if (rootEl) {
  createRoot(rootEl).render(
    // @ts-ignore
    <ErrorBoundary silent>{React.createElement(MobileNavLinksCMS, rootEl.dataset)}</ErrorBoundary>
  );
}
