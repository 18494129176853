import 'jquery';
import 'jquery.cookie';
import 'jquery.inputmask';
import 'slick-carousel';
import 'object-fit-images';
import './axios.config';
import { initDatadogRum } from './datadog';
import { initUtmz } from './vendor/utmz';
import * as bootstrap from 'bootstrap';

// === Console Fallback ===
window.console =
  window.console ||
  (function () {
    var c = {};
    c.log =
      c.warn =
      c.debug =
      c.info =
      c.error =
      c.time =
      c.dir =
      c.profile =
      c.clear =
      c.exception =
      c.trace =
      c.assert =
        function (s) {};
    return c;
  })();

// === Monitoring ===
initUtmz(); // Analytics Campaign
initDatadogRum(); // Real user monitoring

// === jQuery ===
window.$ = $;

$.fn.exists = function () {
  return this.length !== 0;
};

// === Bootstrap ===
window.bootstrap = bootstrap;
